.blog-page-category-section{

  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  .head-subhead{
    display : flex;
    flex-direction: column;
    .head{
        display:flex;
        flex-direction: row;
        gap:6px;
        align-items: center;
        .icon{
            width: 32px;
            height: 32px;
            @media screen and (max-width:768px) {
              width: 24px;
              height: 24px;
          }
        }
        .heading{
            font-family: 'Open Sans',sans-serif;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            color: var(--black-100, #1e1e26);
            @media screen and (max-width:768px) {
                font-size: 16px;
                line-height: 28px;
                text-align: left;
                margin-bottom: 0;
            }
        }
    }
    .subhead{
        font-family: 'Open Sans',sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: inline-block;
        color: #78787D;
        text-align: left;
        @media screen and (max-width:768px) {
            font-size: 14px;
            line-height: 22px;
        }
        

    }
}

.category-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    margin-bottom: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  
  .category-tabs button {
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    width: max-content;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    @media screen and (max-width:1024px) {  
      min-width: fit-content;
      max-width: max-content;
      padding: 12px;

      }
    

  }
  
  .category-tabs button.active {
    background-color: #22BBB0;
    color: white;
  }

  .grid-container {
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Three equal-width columns */
    gap: 10px; 
    @media screen and (max-width:1024px) {  
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between grid items */
    }

    .grid-item {
      // background-color: #f0f0f0;
      // border: 1px solid #ccc;
      padding: 20px;
      text-align: left;
      border-radius: 16px;
    
      img {
        border-radius: 16px;
        width: 40.8vw; 
        height: 28.3vh;
        object-fit: cover;
      }
    
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        gap: 10px; /* Space between grid items */
        
        
        &:nth-child(1) {
          display: flex;
          flex-direction: column;
          gap: 10px; /* Space between grid items */
          padding: 0;
    
          img {
            width: 100%;
            height: 233px;
            display: block;
            margin-bottom: 10px;
          }
        }
    
        &:not(:first-child) {
          // flex: 0; /* Ensure it takes the remaining space */
          height: 150px;
          padding: 0;
          
          img {
            border-radius: 16px;
            // width: 45% !important;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    
      .mweb {
        @media screen and (max-width: 1024px) {
          text-align: left;
          display: flex;
          flex-direction: column;
        }  
        h3 {
          margin: 15px 0 5px;
          font-family: 'Open Sans', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          color: #000;
      
          @media screen and (max-width: 1024px) {
            margin: 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }
      
        p {
          font-size: 14px;
          color: #555;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
      
          @media screen and (max-width: 1024px) {
            visibility: hidden;
      
          }
        }
      
        a {
          display: inline-block;
          margin-top: 10px;
          color: #22BBB0;
          text-decoration: none;
          font-family: 'Open Sans',sans-serif;
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          @media screen and (max-width: 1024px) {       
             font-size: 16px;
             font-weight: 500;
             line-height: 24px;
             text-align: left;
       
           }
        }
      }
    }
     
      .item1 {
    
        @media screen and (min-width:1024px) { 
    
        grid-row: span 2; /* Make the first item span two rows */
        grid-column: span 1; /* Span one column */
        img {
            border-radius: 16px;
            width: 40.8vw; /* Approximately 70% of 587px relative to a standard viewport width */
            height: 88.3vh !important;
            object-fit: cover;
      }
    }
      }
      .item2 {
          padding-bottom: 0 !important;
      }
      .item3 {
        padding-top: 0 !important;
      }
  }

  .view-all-cta{
    margin: auto;
    width: 178px;
    height: 48px;

    border-radius: 40px;
    background: #22BBB0;
    box-shadow: 0px 4px 12px 0px #00000014;
    color: #000;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 1024px) {
      margin: auto;
      width: 209px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #22BBB0 !important;
      box-shadow: 0px 4px 12px 0px #00000014;
      background-color: #FFFFFF ;
      color: #22BBB0;
    }
}
  
//   .grid-item {
//     height: auto;
//     background-color: #f0f0f0;
//     border: 1px solid #ccc;
//     padding: 20px;
//     text-align: center;

//     @media screen and (max-width:1024px) {  
//       display: flex;
//       flex-direction: row;
//       gap: 10px; /* Space between grid items */
//       &:nth-child(1){
//         display: flex;
//       flex-direction: column;
//       gap: 10px; /* Space between grid items */
        
//     }
//       }

//       img {
//         // width: calc(100% / (587 / 286));
//         // object-fit: cover;
//         // height: calc(100% / (587 / 286));
//         border-radius: 16px;
//         // height: calc(70 * (286px / 100vh * 100));
//         // width: calc(70 * (587px / 100vw * 100)) ;
//         // width: calc(70% * (587px / 1440)); /* Scale width relative to a reference viewport width */
//         // height: calc(70% * (286px / 900));
//         width: 40.8vw; /* Approximately 70% of 587px relative to a standard viewport width */
//         height: 22.3vh;
//         object-fit: cover;

//         @media screen and (max-width:1024px) {  
//           &:nth-child(1){
//             width: 100%;
//             height: 233px;
//             display: block;
//             margin-bottom: 10px;     
//           }
//           &:not(:first-child){ 
//             border-radius: 16px;
//             width: 50% !important; 
//             height: 100%;
//             object-fit: cover;
//           }
        
//   }
// }

//         .mweb{
//     //       @media screen and (max-width:1024px) {  
//     //         &:not(:first-child){ 
//     //           display:flex;
//     //           flex-direction: column;
//     //         }
          
//     // }
//           h3 {
//             margin: 15px 0 10px;
//             font-family: 'Open Sans', sans-serif;
//             font-size: 20px;
//             font-weight: 500;
//             line-height: 32px;
//             text-align: left;
    
//             }
//           p {
//             font-size: 14px;
//             color: #555;
//             font-family: Open Sans;
//             font-size: 16px;
//             font-weight: 400;
//             line-height: 24px;
//             text-align: left;
//             display: -webkit-box;
//             -webkit-line-clamp: 2;
//             -webkit-box-orient: vertical;
//             overflow: hidden;
    
//             }
//         a {
//             display: inline-block;
//             margin-top: 10px;
//             color: #22BBB0;
//             text-decoration: none;
//         }
//         }
    
//   }

}



  
  

  