@import "../../assets/styles/font_color_mixins";
@import "_variable.scss";

.home-page {

.homePagebannerCarousel {  

    width: 100% !important;
    margin: auto;
    background: #E9F8F7;
    padding: 50px 0 ;
    @media screen and (max-width: 739px) {
        padding-top: 24px;
        padding-bottom: 24px;
        margin-top: 0px;
    }

.Swipperr {
    width: 95%;
    height: auto;
    margin-left: 5%;   
}

.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    width: 30px;
    height: 30px;
    color:  #22bbb0;
    position: relative;
    top: 50%;
    bottom: -15px;
}
.swiper-pagination-bullet-active{
    width: 32px !important;
    height: 10px !important;
    border-radius: 12px !important;
}
  .swiper-pagination-bullet {
    background-color: #21bbaf; /* Default bullet color */
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }

.homePagebannerCarousel .Swipperr .swiper {
    position: relative;
    height: auto;
}

.plantripbox {
    width: 480px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    flex-shrink: 0;
    border-radius: 12px;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    display: inline-block;
    background: #FFFFFF;
    border: 0.8px solid #D2D2D4;
    box-shadow: 0px 3.86px 7.19px 0px #00000007;
    box-shadow: 0px 12.97px 24.17px 0px #0000000B;
    padding:16px;

    .inner-div{
        position: relative;
        border-radius: 12px;
        height: 100%;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            display: block;
            border-radius: 12px;
            object-fit: fill;
            border-radius: 12px;
            background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
            background-repeat: no-repeat;
            background-size: 400px 400px;
            display: inline-block;
            position: relative;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            @media screen and (max-width:769px) {
                aspect-ratio: 16/10;
            }
        }
        .connecting-div{
            width: 100%;
            height:max-content;
            min-height: 18%;
            display: flex;
            flex-direction: row;
            background: #E9F8F7;
            justify-content: space-between;
            padding: 8px;
            border-radius: 12px;
            position: absolute;
            bottom: -1px;
    
            .leftpart{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 72%;
    
                .connecting-head{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                    margin-bottom: 0;
                    @media screen and (max-width:992px) {
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }
                .connecting-cities{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: left;
                    color: #1E1E26;
                    margin-bottom: 0;
                    @media screen and (max-width:992px) {
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 18px;
                    }
                }
            }
            .rightpart{
                width: 28%;
                font-family: 'Open Sans',sans-serif;
                font-size: 14px;
                font-weight: 600;
                line-height: 26px;
                text-align: right;
                color: #00A095;
                background: #E9F8F7;
    
                @media screen  and (max-width:992px) {
                    font-size: 12px;
                    line-height: 20px;
                    width: 32%;
                }
            }
        }
    }
}

.modal {
    width: 50vw;
    @media screen and (max-width:768px) {
        width: 86%;
        margin-left: calc((100vw - 86vw) / 2) !important;
        margin-right: calc((100vw - 86vw) / 2) !important;
      }
}

@media (max-width:1199px) {
     .homePagebannerCarousel .Swipperr {
        margin: 0px 0 0 0;
    }
    .homePagebannerCarousel .Swipperr {
        margin: 0px 4% 0 4%;
        height: auto;
        padding: 56px 10px 50px;
    }

}

@media (max-width:767px) {  
     .homePagebannerCarousel .Swipperr {
        padding: 35px 0 70px;
    }
     .homePagebannerCarousel .Swipperr .swiper{
        padding: 0 0px 15px;
    }
}

}


  

.flexible-container {

    width: 89%;
    // max-width: 1266px; /* Limit width to 1266px */
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin: auto;
  
    @media screen and (max-width: 1365px) {
      width: 89%;
      margin: auto;
      margin-top: 24px ;
    }
  
    @media screen and (min-width: 1406px) {
      width: 100%; 
      max-width: 1266px; 
      margin: auto; 
      margin-top: 24px;
    }
  
    @media screen and (max-width: 1024px) {
      width: 90%;
      margin: auto;
    }

    .HomePage-Gallery{
      width: 100%;
      height: fit-content;
      margin:auto;
      //margin-top: 24px;
      margin-bottom: 60px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      // border: 1px solid red;
      gap :14px;
      @media screen and (max-width:739px) {
          margin: 24px auto 0px;
          gap :10px;
      }
    
      .gallery-icon-Head{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          @media screen and (max-width:739px) {
              gap : 6px;
          }
          
          img{
              width: 32px;
              height: 32px;
              @media screen and (max-width:739px) {
                  width: 20px;
                  height: 20px;
              }
    
          }
          h2{
              font-family: 'Open Sans', sans-serif;
              font-size: 24px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: left;
              margin-bottom: 0;
              color: #1E1E26;
              @media screen and (max-width:739px) {
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 28px;
                  
              }
          }
      }
      .gallery-category-navigation{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .gallery-categories{
              width: auto;
              display: flex;
              gap: 10px;
              flex-direction: row;
              justify-content: space-between;
              @media screen and (max-width:1024px) {
                  width: 100%;
                  display: flex;
                  height: 40px;
                  flex-direction: row;
                  justify-content: space-between;
                  overflow-x: scroll;
                  gap: 10px;
                  margin-bottom: 6px;
                  scrollbar-width: none;
              }
              .g-category{
                  width: auto;
                  padding: 8px 16px !important;
                  border-radius: 12px;
                  border: 1px solid #D2D2D4 !important;
                  font-size: 16px;
                  
                  @media screen and (max-width:1024px) {
                      width: fit-content;
                      height: 38px;
                      padding: 8px 16px !important;
                      border-radius: 12px;
                      font-family: 'Open Sans',sans-serif;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;
                      border: 1px solid #D2D2D4 !important;
    
                  }                
              }
          }
          .g-navigation{
              width:10%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              button{
                  width:48px;
                  height:48px;
                  background: #FFFFFF;
                  border-radius: 12px;
                  box-shadow: 0px 4px 14px 0px #00000017;
                  img{
                      width:7px;
                      height:14px;
                  }
    
              }
          }
      }
    
      .slider{
          max-width: 100%;
    
          .swiper{
              display: flex;
              width: 100% !important;
              @media screen and (min-width:1024px){
                  height: 300px !important;
              }
              @media screen and (max-width:450px){
                  height: 165px !important;
              }
              
              align-items: flex-start;
            
              .swiper-wrapper{
      
                  width: 89vw !important;
                  height: 100% !important;
                  .swiper-slide,.swiper-slide-active{
                      text-align: center;
                      font-size: 18px;
                      background: #fff;
                      margin: 0;
                      
                      @media screen and (min-width:1024px){
                          height: 286px;
                          cursor: pointer;
                          position: relative;
                          overflow: hidden;
                          text-align: center;
                      }
                      @media screen and (max-width:450px){
                          height: 150px;
                          width: 45%;
                      }
                    
                      /* Center slide text vertically */
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      
                      img{
                          width: 100%;
                          height: 100%;
                          border-radius: 16px;
                      
                          @media screen and (min-width:1024px){
                              //width: 100%;
                              height: 100%;
                              
                          }
                          @media screen and (max-width:450px){
                              width: 100%;
                              height: 100%;
                          }
                      }
      
                      .fadedbox {
                          background-color: #666666;
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          color: #fff;
                          transition: all 300ms ease-out;
                          opacity: 0;
                          width: 100%;
                          height: 100%;
                          padding: 50px 20px;
                          text-align: center;
                          border-radius: 16px;
                          @media screen and (max-width: 768px) {
                              padding: 10%;
                              text-align: center;
                          }
                      
                          &:hover {
                            opacity: 0.8;
                          }
                          &:hover .para,
                          &:focus .para {
                            
                                opacity: 1;
                                transform: translateY(0px);
                            
                        }
                        &:hover .title,
                        &:focus .title {
                              opacity: 1;
                              transform: translateY(0px);
                              }
        
                          .text {
                            transition: all 30ms ease-out;
                            transform: translateY(30px);
                          }
                        
                          .title {
                              font-family: "Open Sans";
                              font-size: 24px;
                              font-weight: 700;
                              line-height: 36px;
                              text-align: left;
                              opacity: 0;
                              transition-delay: 0.1s;
                              transition-duration: 0.3s;
                              @media screen and (max-width: 768px) {
                                  font-family: "Open Sans";
                                  font-size: 14px;
                                  font-weight: 500;
                                  line-height: 16px;
                                  text-align: left;
                              }
                          }
                          .para {
                            font-family: "Open Sans";
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: left;
                            color: #FFFFFFCC 80%;
                            opacity: 0;
                            transition-delay: 0.1s;
                            transition-duration: 0.3s;
                            @media screen and (max-width: 768px) {
                              font-family: "Open Sans";
                              font-size: 10px;
                              font-weight: 300;
                              line-height: 14px;
                              text-align: left;
                              }
                          }
                        } 
                        
                       
                    }
                  
              }
            
           
      
          }
    
      }
      .swiper-button-next:after,
      .swiper-button-prev:after {
        display: none;
      }
    }

    .our-routes-homepage{
      width: 100%;
      height: auto;
      margin:auto;
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      @media screen and (max-width: 450px) {
          width: 100%;
          height: auto;
          display: flex;
          margin: 15px auto 0px;
          align-items: flex-start;
          flex-direction: column;
          gap :10px;
          padding: 0 ;
      }
      .our-Rotes-heading{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          @media screen and (max-width:739px) {
              gap : 6px;
          }
          img{
              width: 32px;
              height: 32px;
              @media screen and (max-width:450px) {
                  width:20px;
                  height:20px;
              }
          }
          h2{
              font-family: "Open Sans";
              font-size: 24px;
              font-weight: 700;
              line-height: 48px;
              letter-spacing: 0em;
              text-align: left;
              margin-bottom: 0px;
              @media screen and (max-width:450px){
                          font-family: 'Open Sans' sans-serif;
                          font-size: 18px;
                          font-weight: 700;
                          line-height: 28px;
                          text-align: center;
              }
          }
  
      }
      .our-routes-desc-navigation{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
              p{
                  font-family: Open Sans;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: 0em;
                  text-align: left;
                  margin: 0;
                  margin-left: 10px;
                  @media screen and (max-width:450px) {
                      //styleName: Body 2/App/14/Regular;
                      font-family: 'Open Sans';
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 22px;
                      text-align: left;
                      margin: 0;
                  }
              }
              .our-routes-navigation{
                  width:10%;
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  margin-right: 1%;
                  button{
                      width:48px;
                      height:48px;
                      img{
                          width:7px;
                          height:14px;
                      }
                  }
          }
      }
      .slider{
          width:100%;
          aspect-ratio: 5/2;
          @media screen and (max-width: 450px){
              // height:150px;
              width: 100%;
              height: auto;
          }
          .swiper{ 
          width:100%;
          height: 100%;
          
          .swiper-wrapper{
              width:100%;
              // height: 100%;
              //border: 2px solid green;
              display: flex;
              @media screen and (max-width:450px){
                  width:100%;
                  height:auto;
                  //border: 2px solid green;
                  display: flex;
                  //gap: 6%;  
              }
              .swiper-slide{
                  width: auto;
                  aspect-ratio: 1/1;
                  margin-top: 0px !important;
                  @media screen and (max-width: 450px) {
                      width:40%;
                      aspect-ratio: 1/1;
                  }
                  .imagediv{
                      width:100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      transition: transform 0.1s ease-in-out;
  
                      .is-trending-routes{
                          // background: #F8EC4E;
                          // border-radius: 20px;
                          // font-family: 'Open Sans' ,sans-serif;
                          // font-style: normal;
                          // font-weight: 700;
                          // font-size: 12px;
                          // line-height: 16px;
                          // text-transform: uppercase;
                          // color: #1E1E26;
                          // margin-bottom: 0;
                          // padding:4px 8px;
                          position: absolute;
                          top: 0px;
                          transform: translateX(0%);
                          width: auto;
                          height: auto;
  
                          @media screen and (max-width:450px){
  
                              // background: #F8EC4E;
                              // border-radius: 15px;
                              // font-family: 'Open Sans' ,sans-serif;
                              // font-style: normal;
                              // font-weight: 400;
                              // font-size: 10px;
                              // line-height: 10px;
                              // text-transform: uppercase;
                              // color: #1E1E26;
                              // margin-bottom: 0;
                              // padding:2px 4px;
                              position: absolute;
                              top: 0px;
                              transform: translateX(0%);
                              
                          }
                        }
                      img{
                          margin: auto;
                          width:100%;
                          height:100%;
                          aspect-ratio: 4/4;
                          // background: #ffffff;
                          border-radius: 24px;
                      }
                      .banner{
                          height: 37px;
                          width: 124px;
                          position: absolute;
                          bottom: 4%; 
                          transform: translateX(0%);
                          @media screen and (max-width : 450px){
                              min-width:84px;
                              max-width: fit-content;
                              height: 26px;
                              position: absolute
                          }
                          .cityname{
                              height: 100%;
                              background-color: #ffffff;
                              font-family: "Open Sans", sans-serif;
                              font-size: 14px;
                              font-weight: 700;
                              line-height: 16.9px;
                              letter-spacing: 0em;
                              text-align: center;
                              border-radius: 10px;
                              display: flex;
                              flex-direction: column;
                              justify-content: center;
                              margin: 0 !important;
                              @media screen and (max-width : 768px){
                              font-size: 12px;
                              font-weight: 600;                                
                              }
                              
                          }
                      }
                  }
                  .imagediv:hover {
                      transform: rotate(3deg);
                      transform: rotate(-3deg);
                    }
                    
              }
          }
      }
      }
      .swiper-button-next:after,
      .swiper-button-prev:after {
        display: none;
      }
    }


    .HomePage-Star-Section{
      width: 100%;
      height: fit-content;
      margin:auto;
      //margin-top: 24px;
      margin-bottom: 24px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      // border: 1px solid red;
      gap :14px;
      @media screen and (max-width:739px) {
          margin: 24px auto 0px;
          gap :10px;
      }
      .title-n-navigation{
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content:space-between;
          align-items: center;
          
          .head-n-subhead{
  
              display: flex;
              flex-direction: column;
              // align-items: center;
  
              .star-icon-Head{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 12px;
                  margin-bottom: 0;
                  @media screen and (max-width:739px) {
                    gap : 6px;
                  }
                  img{
                      width: 32px;
                      height: 32px;
                      @media screen and (max-width:739px) {
                          width: 20px;
                          height: 20px;
                      }
                  }
                  h2{
                      font-family: 'Ubuntu', sans-serif;
                      font-size: 24px;
                      font-weight: 700;
                      line-height: 42px;
                      letter-spacing: 0em;
                      text-align: left;
                      margin-bottom: 0;
                      @media screen and (max-width:739px) {
                          font-weight: 700;
                          font-size: 18px;
                          line-height: 28px;
                          color: #1E1E26;
                          margin-bottom: 0px;
                      }
                  }
              }
              .sub-head{
                  font-family: 'Open Sans',sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  margin-bottom: 0;
                  margin-left: 10px;
                  @media screen and (max-width:739px) {
                      font-size: 14px;
                      line-height: 22px;
                  }
              }
  
          }
  
          .slide-navigation{
              width:10%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              button{
                  width:48px;
                  height:48px;
                  background: #FFFFFF;
                  border-radius: 12px;
                  box-shadow: 0px 4px 14px 0px #00000017;
                  img{
                      width:7px;
                      height:14px;
                  }
  
              }
          }
  
      }
  
      .Swipperr {
          width: 100%;
          height: auto !important;
      .swiper{
          height: auto ;
          .swiper-slide{
              height: auto !important;
          .main-div {
              // width: 200px;
              // @media screen and (min-width:991px){
              //     min-height: 244px;
              //     height: auto; }
              // @media screen and (min-width:1420px) and (max-width:1500px){
              //     min-height: 274px;
              //     height: auto; }
              // @media screen and (max-width:739px) {
              //     height: auto;
              // }
              height: 100%;
              box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
              border-radius: 16px;
              flex-shrink: 0;
              border-radius: 12px;
              background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
              display: inline-block;
              background: #FFFFFF;
              border: 0.8px solid #D2D2D4;
              box-shadow: 0px 3.86px 7.19px 0px #00000007;
              box-shadow: 0px 12.97px 24.17px 0px #0000000B;
              padding:16px;
              margin-bottom: 2px;
  
              @media screen and (max-width:992px) {
                  padding: 8px;
              }
          
              .inner-div{
                  // position: relative;
                  border-radius: 12px;
                  height: 100%;  
                  justify-content: space-between;  
                  flex-direction: column;   
                  flex-shrink: 0;
                  img {
                      width: 100%;
                      display: block;
                      border-radius: 12px;
                      aspect-ratio: 2/1;
                      object-fit: fill;
                      border-radius: 12px;
                      background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
                      background-repeat: no-repeat;
                      display: inline-block;
                      position: relative;
                      animation-duration: 2s;
                      animation-fill-mode: forwards;
                      animation-iteration-count: infinite;
                      animation-timing-function: linear;
  
                      // @media screen and (max-width:992px) {
                      //     height: 100%;
                      // }
                  }
                  .thought-div{
                      width: 100%;
                      height: max-content;
                      display: flex;
                      flex-direction: row;
                      background: #FFFFFF;
                      justify-content: space-between;
                      padding-top: 8px;
                      align-items: end;
                      // border-radius: 12px;
                      // position: absolute;
                      // bottom: -1px;
                      @media screen  and (max-width:992px) {
                          border-radius: 12px 12px 10px 10px ;
                          // position: absolute;
                          // bottom: 0px;
                          // padding: 8px;
  
                      }
              
                  .thought-text{
                          width: 100%;
                          font-family: 'Open Sans',sans-serif;
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 28px;
                          text-align: left;
                          background: #FFFFFF;
                          margin-bottom: 0;
                          @media screen  and (max-width:992px) {
                              font-size: 12px;
                              line-height: 21.31px;
                          }
                  }
                      
                  }
              }
          }
          }
  
      }
  
  }
  
  
    .modal {
      width: 50vw;
      // @media screen and (max-width:739px) {
      //     width:75vw;
      // }
      @media screen and (max-width:768px) {
          width: 86%;
          
          margin-left: calc((100vw - 86vw) / 2) !important;
          margin-right: calc((100vw - 86vw) / 2) !important;
        }
  }
    
     
  
      .swiper-button-next:after,
      .swiper-button-prev:after {
        display: none;
      }
    }

    .why-nuego{
      width: 100%;
      margin: auto;
      margin-bottom: 24px;
    
        .header{
    
            .title{
                font-family: 'Ubuntu',sans-serif;
                font-size: 28px;
                font-weight: 700;
                line-height: 48px;
                text-align: center;
                color: #1E1E26;
                @media screen and (max-width:991px){
                font-size: 18px; 
                line-height: 28px; 
                text-align: left;
                }
            }
            .sub-title{
                font-family: 'Open Sans',sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #78787D;
                @media screen and (max-width:991px){
                line-height: 22px;
                font-size: 14px;
                text-align: left;
                }
            }
        }
    
        .swiper {
            width: 100%;
            height: 100%;
            .swiper-slide {
              text-align: center;
              height: 312px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              @media screen and (max-width:991px){
              align-items:flex-start;
              height: auto;
              }
              .featutres-img {
                width: 160px;
                height: 160px;
                @media screen and (max-width:991px){
                width: 65px;
                height: 65px; 
                }
              }
              .sub-title{
                font-family: 'Ubuntu',sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 32px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #1E1E26;
                @media screen and (max-width:991px){
                font-size: 16px;  
                text-align: left;             
                }
              }
              .para{
                font-family: 'Open Sans',sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                text-align: center;
                color: #78787D;
                @media screen and (max-width:991px){
                line-height: 21px;
                text-align: left;
                }
              }
            }
          }
          
    }

    .refer-earn{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0px 32px;
      width: 100%;
      height: 250px;
      background: #FFE9E9;
      background: linear-gradient(90deg, #FDE9EA 0%, #FFE3E4 100%);
      // background-image: url("../../../../assets/re-rewamp/Prioritizing\ Women’s\ Safety_Desktop.jpg");
      background-repeat: no-repeat;
      border-radius: 19px;
      // position: relative;
      background-size: 100% 100%;
      background-position: center;
      // border: 1px solid #0000001A;
      
      @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 24px 16px;
          // width: 90%;
          height: 214px;
          background-image: url('../../assets/re-rewamp/Prioritizing\ Women’s\ Safety_mWeb_343\ X\ 214\ px.webp');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 8px;
          margin: 24px auto 0px;
          position: relative;
      }
   
      .sub-div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          height: 208px;
   
          @media screen and (max-width: 768px) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 93%;
              height: auto;
              display: flex;
              justify-content: space-between ;
          }
   
          h2 {
                  font-family: "Ubuntu",sans-serif !important;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 36px;
                  text-align: left;
                  color: #1E1E26;
  
                  @media screen and (max-width: 768px) {
                  font-family: 'Ubuntu',sans-serif;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 27px;
                  text-align: left;
  
              }
          }
          ul{
              display:flex;
              flex-direction: column;
              gap: 12px;
              list-style: none;
              padding-left: 0 ;
              margin-bottom: 0;
  
              li{
                  list-style: none;
                  display: flex;
                  flex-direction: row;
                  gap: 5px;
                  img{
                      width: 24px;
                      height: 24px;
                      @media screen and (max-width: 768px) {
                          width: 20px;
                          height: 20px;
                      }
                  }
                  p {
                      font-family: 'Open Sans';
                      font-weight: 400;
                      font-size: 16px;
                      color: #1E1E26;;
                      text-align: justify;
                      line-height: 22px;
                      text-align: left;
                      margin-bottom: 0;
                      @media screen and (max-width: 768px) {
                          font-family: 'Open Sans';
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #1E1E26;
                          text-align: justify;
                      }
                  }
              }
          }
          .bag {
              position: absolute;
              right: 0px;
              bottom: 40%;
              width: 37%;
              height: 30%;
       
              @media screen and (max-width: 1024px) {
                  visibility:hidden ;
              }
      
              img{
                  position: relative;
                  width: 100%;
                  height: 100%;
                  }
          }
          .window {
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 32%;
              height: 40%;
       
              @media screen and (max-width: 1024px) {
                  visibility:hidden ;
              }
      
              img{
                  position: relative;
                  width: 100%;
                  height: 100%;
                  }
          }
      }
   
  
    }


    .emission-prevented {
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      align-items: flex-start;
      padding: 16px 32px;
      width: 100%;
      height: 250px;
      border-radius: 19px;
      position: relative;
      background: #E9F8F7;
      background-size: 100% 100%;
      // border: 1px solid #0000001A;
      box-shadow: 0px 4.85px 9.04px 0px #00000007;
      background-image: url("../../assets/re-rewamp/Greener\ Future_Dektop.png");
  
      @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 24px 16px;
          // width: 90%;
          margin: 24px auto 0px !important;
          height: 214px;
          background-image: url("../../assets/re-rewamp/Greener\ Future_mWeb_343\ X\ 214\ px\ 1.webp");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border-radius: 8px;
          margin: auto;
          position: relative;
      }
   
   
      .statistics {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          height: auto;
          gap: 0px;
   
          @media screen and (max-width: 768px) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              height: auto;
          }
          h3 {
                  font-family: 'Ubuntu',sans-serif;
                  color: #1E1E26;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 48px;
                  text-align: left;
                  margin-bottom: 0 !important;
  
                  @media screen and (max-width: 768px) {
                  font-family: "Open Sans";
                  line-height: 27px;
                  font-size: 18px;
                  font-weight: 700;
                  letter-spacing: 0em;
                  text-align: left;
              }
          }
          .sub-div{
  
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 100%;
  
              .inner-sub-div{
                  display: flex;
                  flex-direction: column;
                  align-items:flex-start;
                  width: 100%;
                  .kgs{
                          font-family: 'Ubuntu',sans-serif;
                          font-style: normal;
                          color: #1E1E26;
                          margin-bottom: 0 !important;
                          font-size: 48px;
                          font-weight: 700;
                          line-height: 52px;
                          text-align: left;
              
                          @media screen and (max-width: 768px) {
                              font-family: 'Ubuntu',sans-serif;
                              font-style: normal;
                              text-align: justify;
                              font-size: 24px;
                              font-weight: 700;
                              line-height: 36px;
              
                          
                      }
  
                  }
                  .sub-text{
  
                      font-family: 'Open Sans',sans-serif;
                      font-style: normal;
                      color:  #1C1C1C;
                      margin-bottom: 0 !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: left;
          
                      @media screen and (max-width: 768px) {
                          font-style: normal;
                          text-align: left;
                          font-size: 12px;
                          font-weight: 400;
                          line-height: normal; 
                  }
  
                  }
              }
               
          button {
              padding: 8px 32px;
              width: 176px;
              height: 48px;
              background: #FFFFFF;
              border-radius: 60px;
              font-family: "Open Sans",sans-serif;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #00A095;
              border: 1.5px solid #22BBB0 !important;
   
   
              @media screen and (max-width: 768px) {
                  padding: 8px 24px;
                  width: 142px;
                  height: 48px;
                  border-radius: 60px;
                  font-size: 16px;
                  font-weight: 600;
                  letter-spacing: 0em;
                  text-align: center;
                  border: 1.5px solid #22BBB0 !important;
              }
          }
  
          }
   
      }
   
      .cloudimg {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 290px;
          height:110px;
   
          @media screen and (max-width: 768px) {
              position: absolute;
              right: 0px;
              bottom: 0px;
              width: 160px;
              height: 72px;
          }
  
          img{
              position: relative;
              width: 100%;
              height: 100%;
              }
      }
    }
  }
  .home-sign-up-container {
    padding: 32px 0 0 0 ;
    position: relative;
    z-index: 4;
    margin-bottom: -17px !important;
    
    @media screen  and (max-width:768px) {
      padding: 0;
    }
    .sign-up-inner-container {
      background-image: url("../../../src/assets/re-rewamp/Page\ END_Saga_Desktop.png");
      padding: 32px 22px;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: contain;
      background-position: center;
      //border-radius: 16px;
    }
    .thank-you {
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      color: #1E1E26;
      margin: 0;
    }
    .support {
      margin: 8px 0 24px 0;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      color: #1E1E26;
      opacity: 80%;
    }
  
    .sign-up {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      color: #1E1E26;
      margin: 0 0 4px 0;
    }
  
    .form-part {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      @media (max-width: 992px) {
        gap: 8px;
        justify-content: flex-start;
  
      }
    }
  
    .email-field {
      border: 1px solid #d2d2d4 !important;
      padding: 12px 16px;
      border-radius: 8px;
      color: #78787d;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
      width: 200px;
      @media (max-width: 992px) {
        width: 170px;
        padding: 8px;
  
      }
   
    }
  
    .sign-up-cta {
      background: #22bbb0;
      box-shadow: 0 4px 12px 0px #00000014;
      padding: 12px 16px;
      border-radius: 40px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      color: #fff;
      white-space: nowrap;
      @media (max-width: 992px) {
        font-weight: 400;
        padding: 8px;
  
      }
    }
    .error-text {
      color: #ff0000;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    .newsletter-img{
      position:absolute;
      bottom:0;
      left:0;
      width: 153px;
      height: 153px;
      @media (max-width: 991px) {
        bottom:0;
        right:0;
        left: auto;
        width: 83px;
        height: 83px;
      }
    }
    @media (min-width: 992px) {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      .sign-up-inner-container {
        background-image: url("../../../src/assets/re-rewamp/Page\ END_Saga_mWeb.png");
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px 200px 40px 200px;
      }
      .thank-you {
        font-size: 24px;
        max-width: 450px;
        width: 100%;
      }
      .support {
        font-size: 18px;
        margin: 8px 0 0 0;
        max-width: 405px;
      }
      .sign-up {
        margin: 0 0 8px 0;
        font-size: 16px;
      }
      .email-field {
        border-radius: 12px;
        font-size: 16px;
        width: 250px;
      }
      .sign-up-cta {
        font-size: 16px;
      }
      .error-text {
        font-size: 14px;
      }
    }
  }
  

  .mini_hr_line {
    background: #E8E8E8;
    border-radius: 10px;
    width: 56px;
    height: 4px;
    position: absolute;
    //transform: translate(-50%, -50%);
    top: 0;
    left: calc(50% - 28px);
  }



  .join {
    background: #F0FAF9;
    border-radius: 24px;
    //border-top-right-radius:24px;
    //border-top-left-radius: 24px;
    padding: 68px 146px 58px 119px;
    position: relative;

    .join_mail {
      width: 140px;
    }

    .title {
      margin-bottom: 40px;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #1E1E26;
    }

    .half_sun {
      position: absolute;
      top: 0;
      right: 0;
      width: 111px;
      border-top-right-radius: 24px;
    }

    .email-id {
      border: 1px solid rgba(30, 30, 38, 0.1);
      border-radius: 12px;
      padding: 20px 60px;
      margin-right: 24px;
      width: 480px;
      background-image: url($background-cdn + "assets/images/Message.png");
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: 20px;

      &:focus-visible {
        outline: none;
        border: 1px solid rgba(30, 30, 38, 0.1);
      }
    }

    .Signup {
      border: none;
      outline: none;
      background: #22BBB0;
      border-radius: 100px;
      padding: 16px 89px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;

      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }

  .MobileTopBus {
    background-color: white;
    padding: 20px 0px 20px;
    background-image: url("../../assets/re-rewamp/NueGo\ Electric\ Bus\ -\ mWeb.png");
    background-size: 100% 100%;
    //position: sticky;
    //top: 78px;
    //overflow: hidden;
    border-radius: 0 !important;
    position: relative;
    .w-48px {
      width: 48px;
    }

    .Rectangle {
      width: 56px;
      position: absolute;
      left: 50%;
      bottom: -48px;
      transform: translate(-50%, 0);
    }

    .Bus_illustration {
      width: 132px;
      height: 86px;
      position: absolute;
      right: 0;
    }

    .title {
      width: 343px;
      padding: 16px 16px 16px 16px;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color:  #1E1E26;
      text-align: center;
      margin: auto;

    }

    .search {
      padding: 0 0 90px 0;
      .enter-place {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        margin: 0 16px !important;
        padding: 20px;

        .start_location {
          padding-bottom: 16px;
          border: none;
          border-bottom: 1px solid rgba(30, 30, 38, 0.12);

          &:focus-visible {
            border: none;
            border-bottom: 1px solid rgba(30, 30, 38, 0.12);
            outline: none;
          }
        }

        .end_location {
          padding-top: 16px;
          border: none;
          outline: none;

          &:focus-visible {
            border: none;
            outline: none;
          }
        }

        .pace_left_icon {
          display: flex;
          align-items: center;
        }

        .mb_input_left_icon {
          width: 16px;
          height: 72px;
        }

        .flip {
          width: 56px;
          height: 56px;
        }
      }
    }

    .date {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      padding: 10px 8px 10px 8px;
      position: relative;
      margin: 16px;

      .today, .Tomorrow {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: rgba(30, 30, 38, 0.6);
        width: 48px;
        height: 48px;
        border-radius: 8px;
        text-align: center;

      }

      .selected-date {
        color: #FFFFFF;
        background-color: #22BCB1;
      }

      .Calendar-icon {
        position: relative;
        left: 15px;
        width: 32px;
        height: 32px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
        transform: translateX(-50%);
      }
      

      .fast-date-select {
        display: flex;
        //margin-left: 16px;
        gap: 4px;
        //height: 24px;
        height: 100%;
        padding: 2px 12px 2px 12px;
      }

      .date-value {
        margin-left: 30px;
      }

      .calender-parent-div{
        color: #FFFFFF;
        background-color: #22BCB1;
        border-radius: 8px;
        padding: 0px 5px;
      }

      .calender {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
      }
      .calender-active {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
        color: #ffffff;
      }
    }

    .holiday-highlight {
      color: #FFDD00;
      transition: all 0.3s ease-in-out;
      :hover{
        color: #FFFF;
      }
    }

    .react-calendar__tile.holiday-highlight {
      color: #FFDD00 !important;
    }
    .react-calendar__tile.holiday-highlight:disabled {
      color: #FFDD00 !important;
    }

    .react-calendar__tile:hover {
      background-color: #22BCB1 !important; 
      color: #FFFFFF !important;
    }
    .react-calendar__tile.holiday-highlight:hover {
      background-color: #22BCB1 !important; 
      color: #FFFFFF !important; 
    }
    .calender-selected{
        width: 100px;
        margin-left: 26px;
        border: none;
        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
        //opacity: 0.1;
        height: 24px;
        outline: none;
        cursor: pointer;
        color: #FFFFFF;
      }

      .calendar-wrapper .react-calendar .react-calendar__navigation {
        margin-bottom: 0px !important;
        height: 26px !important;
      }
      .calendar-wrapper .react-calendar {
        border-radius: 16px 16px 0 0 !important;
      }

      .selected-holiday {
        color: #FFFF !important;
      }
      .calendar-wrapper .react-calendar .react-calendar__month-view__weekdays {
        margin-bottom: 0 !important;
      }

      // .react-calendar__tile:disabled {
      //   background-color: inherit !important;
      //   color: inherit !important;
      //   opacity: 1 !important; /* Ensure it’s visible */
      //   pointer-events: none; /* Prevent clicking but allow styling */
      // }
      

      .Holidays {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3px;
        padding: 8px;
        list-style: none;
        background: #F8F8F9;
        border-radius: 0 0 16px 16px;
        border: 1px solid #D2D2D4;                  
       li {
        display: flex;
        flex-direction: row;
        gap: 2px;
        align-items: center;
        .holiday-info{
          color: #1E1E26;;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }
    }

    .seat-count {
      position: relative;
      display: flex;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 20px 16px;
      margin: 16px;
      color: #1E1E2699;

      .current-count {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
      }

      .inner-count {
        display: flex;
        margin-left: 48px;
        gap: 12px;
        position: absolute;
        right: 26px;
      }

      .seat_count {

        width: 24px;
        margin-right: 12px;
      }

      .count-plus, .count-sub {
        img {
          width: 24px;

        }
      }

    }

    .searct-btn {
      position: relative;
      background: #1E1E26;
      border-radius: 100px;
      padding: 16px 25px 0px 25px;
      height: 56px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      margin: 16px 16px 0px 16px;
      .Search {
        margin-right: 12px;
        width: 20px;
      }
    }

    .btn-start-search {
      border: 1px solid rgba(30, 30, 38, 0.1);
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      margin: 16px;
      padding: 20px 18px;
      background: white;
      position: absolute;
      width: calc(100% - 32px);
      bottom: -48px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(30, 30, 38, 0.6);

      .search {
        margin-right: 14px;
        width: 20px;
        height: 20px;
      }
    }

    .search-input-wrapper {
      position: relative;

      .search-suggestion-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 12px;
        background-color: white;
        padding: 24px 24px 0;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        width: fit-content;
        max-height: 291px;
        height: fit-content;
        overflow-y: auto;
        z-index: 99;
      }
    }

    .mwebsearchBarBusImage{
      position:absolute;
      bottom:0px;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 506px){
        width: 100% !important;
      }
      @media screen and (min-width: 507px) and (max-width: 855px){
        width: 60% !important;
      }
      @media screen and (min-width: 856px) and (max-width: 1023px){
        width: 50% !important;
      }

    }
    

  }

  .top-layer {
    background: #FFFFFF;
    border-radius: 20px 20px 0 0;
    //padding: 20px;
    //padding-top:490px ;
    position: relative;
  }

  // .flexible-container{
  //   @media screen and (max-width:1366) {
  //     width: 89%;
  //     display : flex;
  //     align-items: center;
  //     flex-direction: column;
  //     gap: 24px
  //   }
  
  //   @media screen and (min-width:1366) {
  //     max-width: 1366;
  //     margin: auto;
  //     display : flex;
  //     flex-direction: column;
  //     gap: 24px
  //   }
  // }

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    padding: 8px 16px;
    border-radius: 40px;
  }


  .recent-search-section {

    
      margin: 24px 80px;

    .recent-search-wrapper {
      display: flex;
      overflow-x: auto;
      width: auto;
    }

    .recent-search-item {
      background: #FFFFFF;
      border: 1px solid #E9F8F7;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      border-left: 8px solid #7AD6D0;
    }

    // hide scrollbar for recent-search-wrapper
    .recent-search-wrapper::-webkit-scrollbar {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-track {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-thumb {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-button {
      display: none;
    }

    .recent-search-wrapper::-webkit-scrollbar-corner {
      display: none;
    }

    .recent-search-wrapper::-webkit-resizer {
      display: none;
    }

    .recent-search-wrapper {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  
  @media(max-width:991px) {
    margin: 24px 24px;
  }
  }
}

// components
.homepage {
  .search-suggestion {
    padding: 24px 24px;
    background-color: white;
    border-radius: 12px;
  }


}


.change-color {
  color: #00A095;
}



.view-t-c{
color: var(--white-100, #FFF);
text-align: right;
font-family: "Open Sans" , sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
margin-bottom: 0;
}

.p-x-4{
  @media screen and (min-width: 1023px) {
    padding-left: 4rem !important;
  }
}


.scrollToTopArrow{
  display: inline-flex;
  padding: 3px 5px 5px 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #22BBB0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  right: 20px;
  bottom: 20px;
  // width: 24px;
  // height: 24px;
  cursor: pointer;
  z-index: 99999999;
  img{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3px 4px;
    justify-content: center;
    align-items: center;
  }
}


.EmissionAndReferalslider{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 24px auto;
   }
  @media screen and (max-width: 1023px) {
    width: 100%;
    height: 217px;
    padding-left: 16px;
  }
  .swiper{
    width:100%;
    .swiper-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap:2%;
      .swiper-slide:first-child{
        @media screen and (min-width: 1024px) {
        width:53% !important;
        margin-right: 0px !important;
        }
        @media screen and (max-width: 1023px) {
          width:95% !important;
          margin-right: 0px !important;
        }
        
      }
      .swiper-slide:nth-child(2){
        @media screen and (min-width: 1024px) {
        width:45% !important;
        margin-right: 0px !important;
        }
        @media screen and (max-width: 1023px) {
          width:95% !important;
          margin-right: 0px !important;
        }
        
      }

    }
  }

}


.icon-bar {
  width: 30px;
  height: auto;
  padding: 12px 0px 0px 0px;
  right: 0px;
  gap: 15px;
  border-radius: 4px 0px 0px 4px;
  opacity: 1;
  background: #22BBB0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  .a {
    width: 20px;
    height: 20px;
    padding: 0px 0px 0.24px 0px;
    gap: 0px;
    opacity: 0px;
    display: block;
    text-align: center;
    padding: 16px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    z-index: 9999;

    
  }
  .iconImage{
    width: 20px;
    height: 20px;
  }
  .icon-bar a:hover {
    background-color: #000;
  }
}



