@import "_variable.scss";
// .mweb404search{
    // background-color:  #FFFFFF;
    // position: relative;
    // box-shadow: 0px 4px 16px 0px #00000033;
    // border-radius: 16px;
    // background-image: url($background-cdn + "assets/images/desktop-banner-img.webp");
    
    // .semicircle {
    //     position: absolute;
    //     top: -52px; /* Adjust this value to move the semicircle more into or out of view */
    //     right: -58px; /* Adjust this value similarly */
    //     width: 100px; /* Diameter of the semicircle */
    //     height: 100px; /* Diameter of the semicircle */
    //     background-color: yellow;
    //     border-radius: 50%; /* Makes the shape a circle */
    //     clip-path: inset(51% 56% 0% 0%);; /* Clips the bottom half, creating a semicircle */
    //     overflow: hidden;
    //   }
    // .mweb-head{
    //     padding: 10px 16px ;
    //     font-family: 'Open Sans', sans-serif;
    //     font-size: 20px;
    //     font-weight: 700;
    //     line-height: 30px;
    //     text-align: left;
    //     color: #1E1E26;

    // }
    // .mweb-desc{
    // visibility: hidden;
    // margin: 0px 16px  10px 16px ;
    // font-family: 'Open Sans', sans-serif;
    // font-size: 16px;
    // font-weight: 400;
    // line-height: 24px;
    // text-align: left;
    // }
.MobileTopBus {
    background-color:  #FFFFFF;
    padding: 20px 0px 20px;
    border-radius: 16px;
    background-image: url("../../assets/re-rewamp/Homepage_MWeb_01.jpg");
    background-size: 100% 100% !important;
    //position: sticky;
    //top: 78px;
    //overflow: hidden;
    .w-48px {
      width: 48px;
    }

    .Rectangle {
      width: 56px;
      position: absolute;
      left: 50%;
      bottom: -48px;
      transform: translate(-50%, 0);
    }

    .Bus_illustration {
      width: 132px;
      height: 86px;
      position: absolute;
      right: 0;
    }

    .title {
      // width: 219px;
      padding: 16px 16px 8px 16px;
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #1E1E26;
    }

    .search {
      padding-bottom: 90px;
      .enter-place {
        background: #FFFFFF;
        // border: 1px solid rgba(0, 0, 0, 0.1);
        border: 1px solid #E8E8E9;
        border-radius: 16px;
        margin: 0 16px !important;
        padding: 20px;

        .start_location {
          padding-bottom: 16px;
          border: none;
          border-bottom: 1px solid rgba(30, 30, 38, 0.12);

          &:focus-visible {
            border: none;
            border-bottom: 1px solid rgba(30, 30, 38, 0.12);
            outline: none;
          }
        }

        .end_location {
          padding-top: 16px;
          border: none;
          border: 1px solid #E8E8E9;
          outline: none;

          &:focus-visible {
            border: none;
            outline: none;
          }
        }

        .pace_left_icon {
          display: flex;
          align-items: center;
        }

        .mb_input_left_icon {
          width: 16px;
          height: 72px;
        }

        .flip {
          width: 56px;
          height: 56px;
        }
      }
    }

    .date {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: flex;
      padding: 10px 0px 10px 0px;
      position: relative;
      margin: 16px;

      .today, .Tomorrow {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: rgba(30, 30, 38, 0.6);
        width: 48px;
        height: 48px;
        border-radius: 8px;
        text-align: center;

      }

      .selected-date {
        color: #FFFFFF;
        background-color: #22BCB1;
      }

      .Calendar-icon {
        position: relative;
        left: 15px;
        width: 32px;
        height: 32px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
        transform: translateX(-50%);
      }
      

      .fast-date-select {
        display: flex;
        //margin-left: 16px;
        gap: 4px;
        //height: 24px;
        height: 100%;
        padding: 2px 0px 2px 12px;
      }

      .date-value {
        margin-left: 30px;
      }

      .calender-parent-div{
        color: #FFFFFF;
        background-color: #22BCB1;
        border-radius: 8px;
        padding: 0px 5px;
        margin: 0 8px 0 0;
      }

      .calender {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
      }
      .calender-active {
        width: 100%;
        padding-right: 0px;
        margin-left: 6px;
        border: none;
        border-right: 1.5px solid #1E1E26;
        outline: none;
        color: #ffffff;
      }
    }

    .seat-count {
      position: relative;
      display: flex;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 20px 16px;
      margin: 16px;
      color: #1E1E2699;

      .current-count {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E26;
      }

      .inner-count {
        display: flex;
        margin-left: 48px;
        gap: 12px;
        position: absolute;
        right: 26px;
      }

      .seat_count {

        width: 24px;
        margin-right: 12px;
      }

      .count-plus, .count-sub {
        img {
          width: 24px;

        }
      }

    }

    .holiday-highlight {
      color: #22BCB1;
      transition: all 0.3s ease-in-out;
      :hover{
        color: #FFFF;
      }
    }

    .react-calendar__tile:hover {
      background-color: #22BCB1 !important; 
      color: #FFFFFF !important;
    }
    .react-calendar__tile.holiday-highlight:hover {
      background-color: #22BCB1 !important; 
      color: #FFFFFF !important; 
    }
      .calender-selected{
        width: 100px;
        margin-left: 26px;
        border: none;
        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
        //opacity: 0.1;
        height: 24px;
        outline: none;
        cursor: pointer;
        color: #FFFFFF;
      }

      .calendar-wrapper .react-calendar .react-calendar__navigation {
        margin-bottom: 0px !important;
        height: 26px !important;
      }
      .calendar-wrapper .react-calendar {
        border-radius: 16px 16px 0 0 !important;
      }

      .selected-holiday {
        color: #FFFF !important;
      }
      .calendar-wrapper .react-calendar .react-calendar__month-view__weekdays {
        margin-bottom: 0 !important;
      }

      .Holidays {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3px;
        padding: 8px;
        list-style: none;
        background: #F8F8F9;
        border-radius: 0 0 16px 16px;
        border: 1px solid #D2D2D4;                  
       li {
        display: flex;
        flex-direction: row;
        gap: 2px;
        align-items: center;
        .holiday-info{
          color: #1E1E26;;
          font-family: 'Open Sans';
          font-size: 10px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }
    }

    
    .searct-btn {
      position: relative;
      background: #1E1E26;
      border-radius: 100px;
      padding: 16px 25px 0px 25px;
      height: 56px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      margin: 16px 16px 0px 16px;
      .Search {
        margin-right: 12px;
        width: 20px;
      }
    }

    .btn-start-search {
      border: 1px solid rgba(30, 30, 38, 0.1);
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
      margin: 16px;
      padding: 20px 18px;
      background: white;
      position: absolute;
      width: calc(100% - 32px);
      bottom: -48px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(30, 30, 38, 0.6);

      .search {
        margin-right: 14px;
        width: 20px;
        height: 20px;
      }
    }

    .search-input-wrapper {
      position: relative;

      .search-suggestion-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 12px;
        background-color: white;
        padding: 24px 24px 0;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        width: fit-content;
        max-height: 251px;
        height: fit-content;
        overflow-y: auto;
        z-index: 100;
      }
    }

    .mwebsearchBarBusImage{
      position:absolute;
      bottom:0px;
      right: 20%;
    }
    .jhalar1{
      position:absolute;
      top: 10px;
      right: 10%;
    }
    .jhalar2{
      position:absolute;
      top: 21%;
      left: 5%;
    }
    .jhalar3{
      position:absolute;
      bottom: 20%;
      right: 10%;
    }
    .jhalar4{
      position:absolute;
      bottom:5px;
      left: 5%;
    }
    .jhalar-type1 {
      -webkit-animation: action 1s infinite alternate;
      animation: action 1s infinite alternate;
    
      @-webkit-keyframes action {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-10px);
        }
      }
    
      @keyframes action {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-10px);
        }
      }
    }
  }
// }