.seo-footer{
    width: 100%;
    margin: auto;
    @media screen and (max-width:991px){
     padding: 32px 0px 32px 0px;
    }

    .seo-footer-title-img{

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
  
        .icon-img{
          width: 32px;
          height: 32px;
          @media screen and (max-width: 768px) {
            width: 24px;
            height: 24px;
          }
  
        }
  
        .title{
          font-family: 'Ubuntu',sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 48px;
          margin-bottom: 0;
          text-align: left;
  
          @media screen and (max-width:1024px){
              font-family: 'Ubuntu',sans-serif;
              font-size: 20px;
              font-weight: 600;
              line-height: 32px;
              text-align: left;
  
          }
        }
      }

      .seo-footer-body{

        margin-bottom: 30px;
        border: 1px solid #D2D2D4;
        backdrop-filter: blur(4px);
        padding: 32px 40px 32px 40px;
        border-radius: 16px;
        background-color: #f9f9f9;
        @media screen and (max-width:768px) {
          padding: 32px 10px 32px 10px;
        }

        h1{
            width: 80%;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            color: var(--black-100, #1e1e26);
            font-family: "Ubuntu", sans-serif;
            font-style: normal;
            margin-bottom: 0;
            @media screen and (max-width:768px) {
                padding-top: 50px;
            }
          }

          h2{
           width: 90%;
           font-size: 22px;
           font-weight: 700;
           line-height: 36px;
           color: var(--black-100, #1e1e26);
           font-family: "Ubuntu", sans-serif;
           font-style: normal;
           margin-bottom: 0;
           padding-left: 0;
           @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
          }
         }
          h3{
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            color: var(--black-100, #1e1e26);
            margin-bottom: 0;
          }
          h4{
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-size: 18px;
            font-weight: 600;
            line-height: 32px;
            color: var(--black-100, #1e1e26);
            margin-bottom: 0;
          }
        img{
            align-items: center;
            width:80%;
            height: 450px;
            border-radius: 3%;
            object-fit: cover;
            @media screen and (max-width: 991px) {
            height: 260px;  
            }
        }
       p,ul,li{
           //  color: var(--black-100, #1e1e26);
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            @media screen and (max-width: 991px){
              font-size: 13px;
              line-height: 22px;
            }
        
          }

        strong{
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: var(--black-100, #1e1e26);
        }
        text{
               color: var(--black-100, #1e1e26);
               font-family: 'Open Sans', sans-serif;
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               line-height: 24px;  
        }

        br{
          line-height: 5px;
        }

     

      }

      .read-toggle{
        display: inline-block;
        font-family: 'Open Sans';
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #00A095;
        cursor: pointer;

      }
          
        

    }
