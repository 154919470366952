.route-header-component{
    width: 100%;
    margin: 15px auto;
    background-color: #f8f8f8;
    padding: 32px;
    display: flex;
    gap: 16px;
    border-radius: 16px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      padding: 14px;
    }
    .header-title-img{
      display: flex;
      justify-content: flex-start;
      gap: 12px;
      align-items: center;
      .icon-img{
        width: 32px;
        height: 32px;
        @media screen and (max-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }
      .title{
        font-family: 'Ubuntu',sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 0;
        text-align: left;

        @media screen and (max-width:1024px){
            font-family: 'Ubuntu',sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;
        }
      }
    }
    .accordion{
    display:flex;
    gap:16px;
    flex-direction: column;
    .accordion-item{
        box-shadow: 0px 4px 16px 0px #00000033;
        border: 1px solid #FFFFFF;
        border-radius: 8px;

        .accordion-header{
          .accordion-button{
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color:#1E1E26;
    
            @media screen and (max-width: 991px) {
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
            }
            
    
          }
          .accordion-button:focus{
            box-shadow: none;
          }
    
          .accordion-button:not(.collapsed) {
            background-color: #FFFFFF;
            color:#1E1E26;
          }
          .accordion-button:not(.collapsed)::after {
            background-image: url('../../assets/icons/downArrow-green.svg');
            transform: rotate(0);
            
          }
          .accordion-button::after {
            background-image: url('../../assets/icons/downArrow-green.svg');
            transform: rotate(-180deg);
            
          }
    
        }
        .accordion-body{
          a{

          text-align: justify;
          font-family: 'Open Sans', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: var(--Masters-Union-Secondary-Colors-Black-Black-300, #5B5B5B);
          margin-bottom: 5px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }

          :hover{
            color: var(--Masters-Union-Secondary-Colors-Black-Black-300, #47beb1);
          }

          :nth-child(0){
            color:yellow;
          }

          }

          br{
            line-height: 5px;
          }

          h2{
            width: 90%;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            color: var(--black-100, #1e1e26);
            font-family: "Ubuntu", sans-serif;
            font-style: normal;
            margin-bottom: 0;
          }
          h1{
             width: 80%;
             font-size: 28px;
             font-weight: 700;
             line-height: 36px;
             color: var(--black-100, #1e1e26);
             font-family: "Ubuntu", sans-serif;
             font-style: normal;
             margin-bottom: 0;
             @media screen and (max-width:768px) {
                 padding-top: 50px;
             }
           }
           h3{
             font-family: "Open Sans", sans-serif;
             font-style: normal;
             font-size: 20px;
             font-weight: 600;
             line-height: 32px;
             color: var(--black-100, #1e1e26);
             margin-bottom: 0;
           }
           h4{
             font-family: "Open Sans", sans-serif;
             font-style: normal;
             font-size: 18px;
             font-weight: 600;
             line-height: 32px;
             color: var(--black-100, #1e1e26);
             margin-bottom: 0;
           }
           p,ul>li{
             color: var(--black-100, #1e1e26);
             font-family: 'Open Sans', sans-serif;
             font-size: 14px;
             font-style: normal;
             font-weight: 400;
             line-height: 22px;
             @media screen and (max-width: 768px) {
              font-size: 12px;
            }

             a{
              color: blue;    
              }

             br{
              line-height: 2px;
            }
         
           }
  
         strong{
             font-family: "Open Sans", sans-serif;
             font-style: normal;
             font-size: 16px;
             font-weight: 700;
             line-height: 26px;
             color: var(--black-100, #1e1e26);
         }
          
    
        }
      }

    }

}